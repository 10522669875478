/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './scss/app.scss';
import './scss/_carousel.scss';
import './scss/_global.scss';
import './scss/_vars.scss';
import './scss/_wild.scss';
import './scss/lightgallery.scss';
import './scss/planet.scss';
import './scss/portfolio.scss';
import './scss/visitor.scss';

// const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

// $(document).ready(function() {
//     $('[data-toggle="popover"]').popover();
// });